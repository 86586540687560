import  $ from 'jquery';
import React from 'react';
import { useParams } from "react-router-dom";
import UserToken from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,GetImgCertUser,RespBkMthdMapp,ajaxindicatorstart,ajaxindicatorstop} from '../function_lib/common_lib';
import {CallCMSMapMethod} from '../function_lib/common_cms';
import Front_Header from './front_header';
import Single_Product from '../sample_comp/single_product';
import Footer from './footer';
import QueryString from 'query-string';
import AOS from 'aos';

import {Home_Banner_Sm,Home_Banner_Sm_Landing} from '../sample_comp/home_banner_sm';
import Tab_List from '../sample_comp/tab_list';
import {Tab_List_Comp,Tab_List_Items} from '../sample_comp/tab_list_comp';

import {Top_Banner_With_Card} from '../component_version2/top_banner_with_card';
import {Top_Banner_Vendor} from '../component_version2/top_banner_vendor';
import {Comp_Advaiet_Card_With_Circular_Icon_Center} from '../component_version2/comp_advaiet_card_with_circular_icon_center';
import {Comp_Advaiet_How_It_Works} from '../component_version2/comp_advaiet_how_it_works';
import {Comp_Advaiet_Benefits} from '../component_version2/comp_advaiet_benefits';

let clsObject;
let rf="0";
let queries;
let proData;
let renderData;
let loc="";
class Vendor_Landing extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };
setCartBk(refId){
     //alert(refId);
     this.setState(state =>({viewData:refId}));
     this.forceUpdate();
     $("#offcanvas-cart").addClass("offcanvas-open");
   }
  constructor(props){
    super(props);
    
    this.state={viewData:false};
    this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
    this.state={stLandingvendor:[]};
  
    if(props !=null){
      queries = QueryString.parse(this.props.location.search);
            if(queries.rf!=null){
              rf=queries.rf;
            }
            loc=props.location["pathname"].substr(1);
            loc=loc.split('/')[1];
            //alert(loc);
    }
    //alert("test == "+rf);
  }
    componentDidMount(){
      //alert(rf);
      clsObject=this;
      var map=[];
        map.push({name:"curl_type",value:"getLandingVendor"});
        map.push({name:"res_func",value:"callBackItem"});
        map.push({name:"sec",value:"27KeHNL8l225KpBePgi8Hg=="});
        if(rf!=="0"){
          map.push({name:"party_id",value:rf});
        }else{
          map.push({name:"app_url",value:loc});
        }
        
        map["res_func"]="callBackItem";
        map["curl_type"]="getLandingVendor";
            CallMapMethod(clsObject,map);
      //CallMapMethod(clsObject,mapCat);
     
        AOS.init();
        AOS.init({
          once: true
        });
        const script = document.createElement("script");
        script.src = "/main.js";
        script.async = true;
        //script.onload = () => clsObject.scriptLoaded();
      
        document.body.appendChild(script);
       
    }
    setCateBk(params){
      // alert(params);
      ajaxindicatorstart("loading..data")
      let mapCatItem=[];
      mapCatItem.push({name:"curl_type",value:"vendorsList"});
      mapCatItem.push({name:"res_func",value:"callBackCatItem"});
      mapCatItem.push({name:"sec",value:"27KeHNL8l225KpBePgi8Hg=="});
      mapCatItem.push({name:"item_category_id",value:params});
      mapCatItem.push({name:"parent_id",value:rf});
      mapCatItem["res_func"]="callBackCatItem";
      mapCatItem["curl_type"]="vendorsList";
          CallMapMethod(clsObject,mapCatItem);
      //alert(params);
    }
    callBackCatItem(data,msg){
      //  alert(data);
       ajaxindicatorstop();
      // $("#pgComp").html(msg);
      //alert(data);
      if(data!=="0"){
        //alert(data);
        let itemsData=JSON.parse(data);
        if(itemsData.length>0){

          let tmpListItems= Tab_List_Items(itemsData);
          $("#tab"+itemsData[0]["item_category_id"]).html(tmpListItems);
          RespBkMthdMapp(this,"_addToCart","setCartBk");
         // alert(tmpListItems);
        }
      }
    }

    callBackItem(data,msg){
      //alert(data);
      let getData=JSON.parse(data);
      //alert(getData.user);
      //alert(JSON.stringify(getData.items));
      var strBanner=Home_Banner_Sm_Landing(getData.user);
      clsObject.setState({stLandingvendor:getData.user})
      //alert(JSON.stringify(getData.items));
      var strTabs=Tab_List_Comp(getData.items);
      //alert(strBanner);
       // $("#pgComp").html(strBanner+strTabs);
       $("#pgComp").html(strTabs);
        if(getData.items.length>0){
          this.setCateBk(getData.items[0]["id"]);
        }
        RespBkMthdMapp(this,"_setbackparam","setCateBk");
        
    }
    callBackProList(data,msg){
      //alert(data);
      if(data!=="" && data!=="0"){
        proData=JSON.parse(data);
        this.setState({viewData:true},()=>{
          RespBkMthdMapp(this,"_addToCart","setCartBk");
        });
      }
     
  }
    render(){
        return(<div>
            <Front_Header/>
            {/* <Top_Banner_With_Card/> */}
            <Top_Banner_Vendor landingVendor={this.state.stLandingvendor}/>
            <Comp_Advaiet_Card_With_Circular_Icon_Center/>
           
            <Comp_Advaiet_How_It_Works/>
            <div id="pgComp">
             
             </div>
            <Comp_Advaiet_Benefits/>
            
          {/* <Tab_List/> */}
    <Footer/>
       </div>)
    }
}
export default Vendor_Landing;