import $ from 'jquery';
import React from 'react';
import { findDOMNode } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';

import Pages from './pages';
import Products from './pages/product_list';
import Product_Landing from './pages/product_landing';
import Step_Two from './pages/step_two';
//import Login_New from './pages/login';
import Login_New from './pages/login_new';
import Register_New from './pages/register_new';
import Advaiet_Corporate from './pages/advaiet_corporate';

import ContactUs from './pages/contact_us';
import Medicine_Page from './pages/medicine_page';
import Vendor_List_Page from './pages/vendor_list_page';
import Vendor_Landing from './pages/vendor_landing';
import Test_List from './pages/test_list';
import Home_Collection_Test from './pages/home_collection_test';
//import Blogs from './pages/Blogs_Articles';

//Sample of Component
import AllComp from './sample_comp/all_component';
import Blogs_Page from './pages/blogs_page';
import Blog_Landing from './pages/blog_landing';

//Admin Routing
import Dashboard from './user_pages/dashboard_new';
import Activation_Process from './user_pages/activation_process';
import Vendor_List from './user_pages/vendor_list';
import Merchant_Payments from './user_pages/merchant_payments';
import Vendor_Advaiet_Money from './user_pages/vendor_advaiet_money';
import Vendor_Pro from './user_pages/vendor_pro';
import Package_Submit from './user_pages/package_submit';
import Create_Package from './user_pages/create_package';
import User_Order from './user_pages/order_investigation';
import Home_Collection from './user_pages/home_collection';
import Order_Reports from './user_pages/order_reports';
import View_Order_Items from './user_pages/view_order_items';
import Staff_Users from './user_pages/staff_users';
import View_Result from './user_pages/view_result';
import Invoice_Receipt from './pages/invoice_receipt';
import Dialysis_Module from './pages/dialysis_module';
import View_Test_Report from './pages/view_test_report';

import EndUser_OrderList from './user_pages/endUser_orderlist';





class App extends React.Component{
  componentDidMount() {
    // const script = document.createElement("script");    
    // script.async = true;    
    // script.src = "./assets/js/default/active.js"; 
    // document.body.appendChild(script);   
      }
      navigateURI(pathname){
        //alert(pathname);
      }
  render(){
    return <Router><Switch>
{/* User Route */}
      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/activation_process" component={Activation_Process}/>
      <Route exact path="/package_submit" component={Package_Submit}/>
      <Route exact path="/create_package" component={Create_Package}/>
      <Route exact path="/vendor_list" component={Vendor_List}/>
      <Route exact path="/vendor_pro" component={Vendor_Pro}/>
      <Route exact path="/merchant_payments" component={Merchant_Payments}/>
      <Route exact path="/vendor_advaiet_money" component={Vendor_Advaiet_Money} />
      <Route exact path="/user_order" component={User_Order} />
      <Route exact path="/staff_users" component={Staff_Users}/>
      <Route exact path="/home_collection" component={Home_Collection} />
      <Route exact path="/order_reports" component={Order_Reports} />
      <Route exact path="/view_result" component={View_Result} />
      <Route exact path="/view_order_items" component={View_Order_Items}/>
{/* User Route */}
{/* End-user Route */}
<Route exact path="/my_orderlist" component={EndUser_OrderList} />
{/*End of End-user Route  */}

      <Route exact path="/contactus" component={ContactUs}/>
      {/* <Route exact path="/blogs" component={Blogs_Page} /> */}
      <Route exact path="/blog/:name" component={Blog_Landing} />
      <Route exact path="/products" component={Products}/>
      <Route exact path="/product/:name" component={Product_Landing}/>
       <Route exact path="/vendor/:name" component={Vendor_Landing}/>
      <Route exact path="/medicine/:name" component={Medicine_Page}/>
      {/* <Route exact path="/tests" component={Medicine_Page}/> */}
      <Route exact path="/tests" component={Test_List} />
      <Route exact path="/home_collection_test" component={Home_Collection_Test}/>
      <Route exact path="/vendors/:name" component={Vendor_List_Page}/>
      <Route exact path="/login" component={Login_New} />
      <Route exact path="/Register" component={Register_New}/>
      <Route exact path="/advaiet_corporate" component={Advaiet_Corporate}/>
      <Route exact path="/steptwo" component={Step_Two}/>
      <Route exact path="/dialysis_plus_advaiet" component={Dialysis_Module}/>
      <Route exact path="/orderConfirmation" component={Invoice_Receipt} />
      <Route exact path="/invoice_receipt" component={Invoice_Receipt} />
      <Route exact path="/View/viewTestReport" component={View_Test_Report}/>
    <Route exact path="/*" component={Pages} />
  </Switch></Router>
  }
}
export default App;