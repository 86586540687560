import './comp_advaiet_benefits.css'

export const Comp_Advaiet_Benefits=(props)=>{
    return(
<div class='container-fluid'>
    <div class=" advaiet-benefit-section mt-5">
        <h2 class="text-center main-title">Benefits of Advaiet Health Services</h2>
        
        <div class="row">
            {/* <!-- Benefit 1 --> */}
            <div class="col-md-4">
                <div class="benefit-card">
                    <h5 class="benefit-title">
                        <span class="check-icon">✓</span>
                        Accurate & Reliable Reports
                    </h5>
                    <p class="benefit-description">
                        Our advanced lab technology ensures precise and dependable diagnostic results for informed health decisions.
                    </p>
                </div>
            </div>
            
            {/* <!-- Benefit 2 --> */}
            <div class="col-md-4">
                <div class="benefit-card">
                    <h5 class="benefit-title">
                        <span class="check-icon">✓</span>
                        Fast & Hassle-Free Service
                    </h5>
                    <p class="benefit-description">
                        Enjoy quick sample collection, seamless test booking, and timely digital report delivery at your convenience.
                    </p>
                </div>
            </div>
            
            {/* <!-- Benefit 3 --> */}
            <div class="col-md-4">
                <div class="benefit-card">
                    <h5 class="benefit-title">
                        <span class="check-icon">✓</span>
                        Affordable & Transparent Pricing
                    </h5>
                    <p class="benefit-description">
                        We offer cost-effective diagnostic solutions with clear pricing and no hidden charges.
                    </p>
                </div>
            </div>
            
            {/* <!-- Benefit 4 --> */}
            <div class="col-md-4">
                <div class="benefit-card">
                    <h5 class="benefit-title">
                        <span class="check-icon">✓</span>
                        Expert Consultation Available
                    </h5>
                    <p class="benefit-description">
                        Get guidance from experienced healthcare professionals to understand your reports and health better.
                    </p>
                </div>
            </div>
            
            {/* <!-- Benefit 5 --> */}
            <div class="col-md-4">
                <div class="benefit-card">
                    <h5 class="benefit-title">
                        <span class="check-icon">✓</span>
                        Home Sample Collection
                    </h5>
                    <p class="benefit-description">
                        Convenient doorstep sample collection services ensure safe, hassle-free, and time-saving health checkups.
                    </p>
                </div>
            </div>
            {/* <!-- Benefit 6 --> */}
            <div class="col-md-4">
                <div class="benefit-card">
                    <h5 class="benefit-title">
                        <span class="check-icon">✓</span>
                        Secure & Confidential Reports
                    </h5>
                    <p class="benefit-description">
                        Your medical data is securely stored and accessible only to you for complete privacy.
                    </p>
                </div>
            </div>
        </div>
    </div>
    </div>
        

    );
}

export default Comp_Advaiet_Benefits;