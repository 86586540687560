import {getRandomImagesPath} from '../function_lib/random_images';
import './comp_vendor_thumb.css';
export const Comp_Vendor_Thumb_New=(props)=>{
    
    //alert(JSON.stringify(props));
    return (<div  class="main-blog-area pb-50px">
            <div class="container">
             {/* <div class="row">
                <div class="col-md-12">
                    <div class="heading page__title-bar">
                        <h5 class="title">{props.title}</h5>
                    </div>
                </div>
            </div> */}
            <div class="new-product-slider swiper-container advaiet_listingg slider-nav-style-1">
            <div class={"justify-content-center heading page__title-bar"}> <h5 class="title">{props.title}</h5></div>
            <div class="new-product-wrapper swiper-wrapper  mb-5 ">
                
                {/* content part */}
                {props?.lsData.map((item,index)=>{
                    let imgPath=getRandomImagesPath();
                    if(index>=props.indexFrom && index<=props.indexTo){
                        return <div class="card_for_img swiper-slide">
                        <a href={"../vendor/"+item["app_url"]}>
                    <img src={imgPath} alt=""/>
                    <div class="desc_inside_card">
                        <p>{item["company_name"]}</p>
                        <svg fill="#000000" height="24px" width="24px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 330"><g id="SVGRepo_iconCarrier"><g id="XMLID_2_"><path id="XMLID_4_" d="M145.606,74.393c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l69.393,69.392l-69.393,69.395c-5.858,5.858-5.858,15.355,0,21.213C127.322,258.536,131.161,260,135,260s7.678-1.464,10.606-4.394l80-80.002c2.814-2.813,4.394-6.628,4.394-10.607c0-3.979-1.58-7.794-4.394-10.607L145.606,74.393z"></path><path id="XMLID_5_" d="M165,0C74.019,0,0,74.019,0,165s74.019,165,165,165s165-74.019,165-165S255.981,0,165,0z M165,300c-74.439,0-135-60.561-135-135S90.561,30,165,30s135,60.561,135,135S239.439,300,165,300z"></path></g></g></svg>
                    </div>
                    </a>
                </div>
                    }
                   
                })}
                
            
           
            
                {/* end of content part */}

            </div>
<div class="swiper-buttons">
<div class="swiper-button-next"></div>
<div class="swiper-button-prev"></div>
</div>
</div></div></div>    
                
                )
}
export const Comp_Vendor_Thumb=(props)=>{
    return(
        <div class="container-fluid text-center advaiet_listingg">
            
        <div class="row justify-content-center my-5">
        <h5>Tests from Local Laboratories</h5>
            <div class="col-md-3 col-12 card_for_img">
                <img src="https://cms.advaiet.com/advaiet/16437681860.jpg" alt=""/>
                <div class="desc_inside_card">
                    <p>Indore Path Lab</p>
                    <svg fill="#000000" height="24px" width="24px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 330"><g id="SVGRepo_iconCarrier"><g id="XMLID_2_"><path id="XMLID_4_" d="M145.606,74.393c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l69.393,69.392l-69.393,69.395c-5.858,5.858-5.858,15.355,0,21.213C127.322,258.536,131.161,260,135,260s7.678-1.464,10.606-4.394l80-80.002c2.814-2.813,4.394-6.628,4.394-10.607c0-3.979-1.58-7.794-4.394-10.607L145.606,74.393z"></path><path id="XMLID_5_" d="M165,0C74.019,0,0,74.019,0,165s74.019,165,165,165s165-74.019,165-165S255.981,0,165,0z M165,300c-74.439,0-135-60.561-135-135S90.561,30,165,30s135,60.561,135,135S239.439,300,165,300z"></path></g></g></svg>
                </div>
            </div>
            <div class="col-md-3 col-12 card_for_img">
                <img src="https://cms.advaiet.com/advaiet/16437682620.jpg" alt=""/>
                <div class="desc_inside_card">
                    <p>Madlabs Diagnostic Centre</p>
                    
    <svg fill="#000000" height="24px" width="24px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 330"><g id="SVGRepo_iconCarrier"><g id="XMLID_2_"><path id="XMLID_4_" d="M145.606,74.393c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l69.393,69.392l-69.393,69.395c-5.858,5.858-5.858,15.355,0,21.213C127.322,258.536,131.161,260,135,260s7.678-1.464,10.606-4.394l80-80.002c2.814-2.813,4.394-6.628,4.394-10.607c0-3.979-1.58-7.794-4.394-10.607L145.606,74.393z"></path><path id="XMLID_5_" d="M165,0C74.019,0,0,74.019,0,165s74.019,165,165,165s165-74.019,165-165S255.981,0,165,0z M165,300c-74.439,0-135-60.561-135-135S90.561,30,165,30s135,60.561,135,135S239.439,300,165,300z"></path></g></g></svg>
                </div>
            </div>
            <div class="col-md-3 col-12 card_for_img">
                <img src="https://cms.advaiet.com/advaiet/16438563280.jpg" alt=""/>
                <div class="desc_inside_card">
                    <p>Navin Pathology centre</p>
                    
    <svg fill="#000000" height="24px" width="24px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 330"><g id="SVGRepo_iconCarrier"><g id="XMLID_2_"><path id="XMLID_4_" d="M145.606,74.393c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l69.393,69.392l-69.393,69.395c-5.858,5.858-5.858,15.355,0,21.213C127.322,258.536,131.161,260,135,260s7.678-1.464,10.606-4.394l80-80.002c2.814-2.813,4.394-6.628,4.394-10.607c0-3.979-1.58-7.794-4.394-10.607L145.606,74.393z"></path><path id="XMLID_5_" d="M165,0C74.019,0,0,74.019,0,165s74.019,165,165,165s165-74.019,165-165S255.981,0,165,0z M165,300c-74.439,0-135-60.561-135-135S90.561,30,165,30s135,60.561,135,135S239.439,300,165,300z"></path></g></g></svg>
                </div>
            </div>
            <div class="col-md-3 col-12 card_for_img">
                <img src="https://cms.advaiet.com/advaiet/16438574360.jpg" alt=""/>
                <div class="desc_inside_card">
                    <p>SR Pathology Lab</p>
                    
    <svg fill="#000000" height="24px" width="24px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 330"><g id="SVGRepo_iconCarrier"><g id="XMLID_2_"><path id="XMLID_4_" d="M145.606,74.393c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l69.393,69.392l-69.393,69.395c-5.858,5.858-5.858,15.355,0,21.213C127.322,258.536,131.161,260,135,260s7.678-1.464,10.606-4.394l80-80.002c2.814-2.813,4.394-6.628,4.394-10.607c0-3.979-1.58-7.794-4.394-10.607L145.606,74.393z"></path><path id="XMLID_5_" d="M165,0C74.019,0,0,74.019,0,165s74.019,165,165,165s165-74.019,165-165S255.981,0,165,0z M165,300c-74.439,0-135-60.561-135-135S90.561,30,165,30s135,60.561,135,135S239.439,300,165,300z"></path></g></g></svg>
                </div>
            </div>


        </div>
    </div>
    );
}
export default Comp_Vendor_Thumb;