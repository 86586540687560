import React from 'react';
import './tab_list.css';
import { appGetUrlPath } from '../function_lib/common_cms';
import { getRandomImagesPath } from '../function_lib/random_images';

export function Tab_List_Items(lsData){
    // alert(JSON.stringify(lsData));
    let appPath=appGetUrlPath();
    let strHeader="<section class=\"my-4\">"+
    "<div class=\"container\">"+
        "<div class=\"row\">"+
        "<div class=\"col-md-12\">";
    let strFooter="</div></div></div></section>";
   
    let strResp="";
    for(var k=0;k<lsData.length;k++){
        strResp+="<form><div class=\"cart-item mb-3 box\">"+
        //"<div class=\"shadow-sm shop-cart-item\">"+
        "<div class=\"p-3\">"+"<div class=\"d-flex\">"+
        "<input type=\"hidden\" name=\"item_name\" value=\""+lsData[k]["name"]+"\" />"+
        "<input type=\"hidden\" name=\"item_code\" value=\""+lsData[k]["Item Code"]+"\"/>"+
        "<input type=\"hidden\" name=\"item_rate\" value=\""+lsData[k]["Sales Rate"]+"\" />"+
        "<input type=\"hidden\" name=\"comp_name\" value=\""+lsData[k]["company_name"]+"\" />"+
        "<input type=\"hidden\" name=\"imgs\" value=\""+""+"\" />"+
        "<input type=\"hidden\" name=\"item_rf_id\" value=\""+lsData[k]["id"]+"\" />"+
        "<input type=\"hidden\" name=\"parent_id\" value=\""+lsData[k]["party_id"]+"\" />"+         
        "<div class=\"shop-cart-left\">"+
        "<img src=\"../img_f/def_item.png\" class=\"img-fluid\">"+
        "</div>"+
        "<div>"+
       "<h6 class=\"mb-1 font-weight-bold\">"+lsData[k]["name"]+"</h6>"+
       "<p class=\"m-0 fw-bold\">₹"+lsData[k]["Sales Rate"]+" <del class=\"text-muted small\">₹"+lsData[k]["MRP"]+"</del></p>"+
       "<p class=\"text-muted small mt-1\">1 Packet</p>"+
   "</div></div>"+
   "<div class=\"ms-auto\">"+
       "<div class=\"mb-0 mt-3 border-qty rounded-pill shadow-sm\">"+
           "<a href=\"#\" class=\"btn-primary _addToCart btn-green mybtn\"><i class=\"icon-plus\"></i> <span>ADD</span></a>"+
       "</div>"+
   "</div></div></div>"+
        "</form>";
    }
    return strHeader+strResp+strFooter;
}
export function Tab_List_Comp(lsData){
    var appPath=appGetUrlPath();
    //var strResp="";
    // alert(JSON.stringify(lsData));
    var strHeader="<section class=\"my-4 mt-10\">"+
    "<div class=\"container\"><div class=\"row\"><div class=\"col-md-12\">";
    
    var strFooter="</div></div></div></section>";

    let strResp="";
    let strTabs="<ul class=\"nav nav-tabs justify-content-center scrollmenu\" id=\"myTab\" role=\"tablist\">";
    for(var i=0;i<lsData.length;i++){
        let actTab="";
       
        if(i==0){
            actTab="active";
        }
        // alert(lsData[i]["id"]);
        strTabs+="<li class=\"nav-item\" role=\"presentation\">"+
        "<button class=\"nav-link _setbackparam "+actTab+"\"  param1=\""+lsData[i]["id"]+"\" id=\"tab-"+lsData[i]["id"]+"\" data-bs-toggle=\"tab\" data-bs-target=\"#tab"+lsData[i]["id"]+"\" type=\"button\" role=\"tab\" aria-controls=\"tab"+lsData[i]["id"]+"\" aria-selected=\"true\">"+lsData[i]["Item Category"]+"</button>"+
        "</li>";
    }
    strTabs+="</ul>";
    
    let strTabData="<div class=\"tab-content\" id=\"myTabContent\">";
    for(var i=0;i<lsData.length;i++){
        let actCont="";
        if(i==0){
            actCont="show active";
        }
        strTabData+="<div class=\"tab-pane "+actCont+" fade\" id=\"tab"+lsData[i]["id"]+"\" role=\"tabpanel\" aria-labelledby=\"tab-"+lsData[i]["id"]+"\">"+
        "<h5>"+lsData[i]["Item Category"]+"</h5>"+
        // "<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>"+
    "</div>";
    }
    strTabData+="</div>";
 strResp=strHeader+strTabs+strTabData+strFooter;   
return strResp;
}
export default Tab_List_Comp;