import React from 'react';
import ReactDOMServer from 'react-dom/server';
import {appGetUrlPath,getMainUrl} from '../function_lib/common_lib';
import {getRandomImagesPath} from '../function_lib/random_images';
import Search_Filters from '../component_version2/search_filters';
import Comp_Sorting_Bar from '../component_version2/comp_sorting_bar';
import {Comp_Vendor_Listing} from './comp_vendor_listing';

import './product_listing.css';

export const Order_Data_Horizontal_navtiv=(props)=>{

    return(<div className="shop-category-area pb-10px pt-70px">"
    <div className="container">
        <div className="row">
            <div className="col-lg-12 order-lg-last col-md-12 order-md-first">
            <div className="shop-bottom-area">
            <div className="row">
                {/* start of rotationalCOmpo */}
           {props.items.map((item,index)=>{
            return (<div className="col-md-6 pb-10">
            <form>
          <div className="card p-1 shadow">
                <div className="row">
                <div className="col-sm-6">
                <div className="card-block">
                    <div className="p-2 mb-1 alert-warning mybg text-white">
                        <h5 className="mb-0">Invoice No:{item["Invoice No"]}</h5>
                    </div>
                    <p className="mb-0">Status : <b>{item["collection_status"]}</b></p>
                    <p className="mb-0">Amount : <b>₹ {item["Net Amount"]}</b></p>
                    <p className="text-danger mb-0">Payment Status : <b>{item["Payment Status"]}</b></p>
                   
                </div>
            </div>
                    <div className="col-sm-6">
                    <div className="card-block">
                    <p className="mb-0">Date : <b>{item["date"]}</b></p>
                    <p className="mb-0">Name : <b>{item["Company Name"]}</b></p>
                    <p className="mb-0">Contact No : <b>{item["contact_no"]}</b></p>
                    <p className="text-danger mb-0">Gender :<b>{item["Gender"]}</b></p>
                    <div className="p-2 mb-1 text-white">
                    <a href={"../view_order_items?rf="+item["invoice_id"]} class="btn btn-primary float-right py-2 px-4">Next</a>
                    </div>
                   
                    
                </div>
            </div>
                    
                  
                </div>
            </div>
        </form></div>)
           })}     
                


              </div></div></div></div></div></div>  )

}
export function User_OrderList(lsData){
    //  alert(lsData);
    //view_result url replace with view_order_items
    var appUrtPath=appGetUrlPath();
    var listData=JSON.parse(lsData);
    var strHeader="<div class=\"shop-category-area pb-10px pt-70px\">"+
    "<div class=\"container\">"+
        "<div class=\"row\">"+
            "<div class=\"col-lg-12 order-lg-last col-md-12 order-md-first\">"+
            "<div class=\"shop-bottom-area\">"+
            "<div class=\"row\">";
     var strFooter="</div></div></div></div></div></div>";
     var strResp="";
     for(var i=0;i<listData.length;i++){
      
         

        strResp+="<div class=\"col-md-6 pb-10\">"+
        "<form>"+
      "<div class=\"card p-1 shadow\">"+
            "<div class=\"row\">"+
            "<div class=\"col-sm-6\">"+
            "<div class=\"card-block\">"+
                "<div class=\"p-2 mb-1 alert-warning mybg text-white\">"+
                    "<h5 class=\"mb-0\">Invoice No:"+listData[i]["Invoice No"]+"</h5>"+
                "</div>"+
                "<p class=\"mb-0\">Status : <b>"+listData[i]["collection_status"]+"</b></p>"+
                "<p class=\"mb-0\">Amount : <b>₹"+listData[i]["Net Amount"]+"</b></p>"+
                "<p class=\"text-danger mb-0\">Payment Status : <b>"+listData[i]["Payment Status"]+"</b></p>"+
                
                
            "</div>"+
        "</div>"+
                "<div class=\"col-sm-6\">"+
                "<div class=\"card-block\">"+
                "<p class=\"mb-0\">Date : <b>"+listData[i]["date"]+"</b></p>"+
                "<p class=\"mb-0\">Name : <b>"+listData[i]["Company Name"]+"</b></p>"+
                "<p class=\"mb-0\">Contact No : <b>"+listData[i]["contact_no"]+"</b></p>"+
                "<p class=\"text-danger mb-0\">Gender :<b>"+listData[i]["Gender"]+"</b></p>"+
                "<a href=\"javascript:void(0)\" class=\"btn btn-secondary mybtn11\">Check Report</a>"+
                "<a href=\"../invoice_receipt?rf="+listData[i]["encKey"]+"\" class=\"btn btn-primary mybtn11\">View Invoice</a>"+
                // "<div class=\"p-2 mb-1 text-white\">"+
                // "<a href=\"../view_order_items?rf="+listData[i]["invoice_id"]+"\" class=\"btn btn-primary float-right py-2 px-4\">View Invoice</a>"+
                // "</div>"+
                // "<p class=\"mb-2\">Updated At : 16/02/2022</p>"+
                
            "</div>"+
        "</div>"+
                
              
            "</div>"+
        "</div>"+
    "</form></div>"; 
     }
    return strHeader+strResp+strFooter;
}

export function Order_Data_Horizontal(lsData){
    //  alert(lsData);
    //view_result url replace with view_order_items
    var appUrtPath=appGetUrlPath();
    var listData=JSON.parse(lsData);
    var strHeader="<div class=\"shop-category-area pb-10px pt-70px\">"+
    "<div class=\"container11\">"+
        "<div class=\"row\">"+
            "<div class=\"col-lg-12 order-lg-last col-md-12 order-md-first\">"+
            "<div class=\"shop-bottom-area\">"+
            "<div class=\"row\">";
     var strFooter="</div></div></div></div></div></div>";
     var strResp="";
     for(var i=0;i<listData.length;i++){
      
         

        strResp+="<div class=\"col-md-12 pb-10\">"+
        "<form>"+
      "<div class=\"card p-1 shadow\">"+
            "<div class=\"row\">"+
            "<div class=\"col-sm-4\">"+
            "<div class=\"card-block\">"+
                "<div class=\"p-2 mb-1 alert-warning mybg text-white\">"+
                    "<h5 class=\"mb-0\">Invoice No:"+listData[i]["Invoice No"]+"</h5>"+
                "</div>"+
                "<p class=\"mb-0\">Type : <b>"+listData[i]["Invoice Type"]+"</b></p>"+
                "<p class=\"mb-0\">Status : <b>"+listData[i]["collection_status"]+"</b></p>"+
                "<p class=\"mb-0\">Amount : <b>₹"+listData[i]["Net Amount"]+"</b></p>"+
                "<p class=\"text-danger mb-0\">Payment Status : <b>"+listData[i]["Payment Status"]+"</b></p>"+
                // "<p class=\"mb-2\">Updated At : 16/02/2022</p>"+
                // "<a href=\"javascript:void(0)\" class=\"btn _addToCart btn-primary mybtn\">Add to Cart</a>"+
            "</div>"+
        "</div>"+
                "<div class=\"col-sm-4\">"+
                "<div class=\"card-block\">"+
                "<p class=\"mb-0\">Date : <b>"+listData[i]["date"]+"</b></p>"+
                "<p class=\"mb-0\">Name : <b>"+listData[i]["Company Name"]+"</b></p>"+
                "<p class=\"mb-0\">Contact No : <b>"+listData[i]["contact_no"]+"</b></p>"+
                "<p class=\"text-danger mb-0\">Gender :<b>"+listData[i]["Gender"]+"</b></p>"+
               
                // "<p class=\"mb-2\">Updated At : 16/02/2022</p>"+
                
            "</div>"+
        "</div>"+
        "<div class=\"col-sm-4\">"+
        "<p class=\"mb-0\">At : <b>"+listData[i]["vend_company_name"]+"</b></p>"+
        "<p class=\"mb-0\">UserName : <b>"+listData[i]["vend_username"]+"</b></p>"+
        "<div class=\"p-2 mb-1 text-white\">"+
        "<a href=\"../view_order_items?rf="+listData[i]["invoice_id"]+"\" class=\"btn btn-primary float-right py-2 px-4\">Next</a>"+
        "</div>"+
        "</div>"+
                
              
            "</div>"+
        "</div>"+
    "</form></div>"; 
     }
    return strHeader+strResp+strFooter;
}
export function Vendor_Listing_Horizontal(lsData,filters,isSearchBar=false,isFilterOutput=false){
    var appUrtPath=appGetUrlPath();
    var listData=JSON.parse(lsData);
    let colsSet="col-lg-12 col-md-12";
    let strSearchBar="";
    if(isSearchBar===true && isFilterOutput===false){
   
  colsSet="col-lg-9 col-md-9";
//   searchbar logic
//strSearchBar="<div class=\"col-lg-3 col-md-3\">";
strSearchBar+=ReactDOMServer.renderToString(<Search_Filters filters={filters} />);
strSearchBar+="<div class=\"mobile-filter-button\">"+
    "<a href=\"#0\" class=\"btn btn-warning open_filters filter-button\">"+
        "<i class=\"ion-ios-color-filter-outline12 ti-filter12 fa fa-sliders white my-float\"></i>"+
    "</a>"+
   "</div>";
//strSearchBar+="</div>";
}
let strHeader="";
let strFooter="";
if(isFilterOutput===false){
    let strSorting=ReactDOMServer.renderToString(<Comp_Sorting_Bar count={listData.length}/>);

    strHeader="<div class=\"shop-category-area pb-10px pt-10px\">"+
    "<div class=\"container\">"+
        "<div class=\"row ser_comp_f\">"+
        strSearchBar+
            "<div class=\""+colsSet+" order-lg-last order-md-first\">"+
            "<div class=\"shop-bottom-area\">"+
            strSorting+
            "<div class=\"row\" id=\"pro_list_parent\">";
    strFooter="</div></div></div></div></div></div>";
}


    if(isFilterOutput){
        strHeader="";
        strFooter="";
    }

     var strResp="";
     for(var i=0;i<listData.length;i++){
        var imgPath="default-product.png"; 
        var urlTitle= listData[i]["icon_img"];
        
        urlTitle=urlTitle.replace("?","_");
        urlTitle=urlTitle.replace(" ","-");
        /*
        if(listData[i]["icon_img"]!=""){
         imgPath="app_user_img/"+listData[i]["icon_img"];
          }
          */
         imgPath=getRandomImagesPath();

         strResp+=ReactDOMServer.renderToString(<Comp_Vendor_Listing vendData={listData[i]}/>);
         //break;
        /*
        strResp+="<div class=\"col-md-6 pb123-10\">"+
        "<form>"+
        "<input type=\"hidden\" name=\"item_name\" value=\""+listData[i]["name"]+"\" />"+
        "<input type=\"hidden\" name=\"item_code\" value=\""+listData[i]["Item Code"]+"\"/>"+
        "<input type=\"hidden\" name=\"item_rate\" value=\""+listData[i]["Sales Rate"]+"\" />"+
        "<input type=\"hidden\" name=\"comp_name\" value=\""+listData[i]["company_name"]+"\" />"+
        "<input type=\"hidden\" name=\"imgs\" value=\""+imgPath[0]+"\" />"+
        "<input type=\"hidden\" name=\"item_rf_id\" value=\""+listData[i]["id"]+"\" />"+
        "<input type=\"hidden\" name=\"parent_id\" value=\""+listData[i]["party_id"]+"\" />"+
        "<div class=\"row room-item m-0 mb-4 g-box-shadow\">"+
        "<div class=\"col-md-5 col-lg-12 col-xl-5 p-0\" style=\"min-height: 270px;\">"+
        "<div class=\"position-relative h-100\">"+
        "<img class=\"position-absolute w-100 h-100\" src=\"../"+imgPath+"\" alt style=\"object-fit: cover;\">"+
        "</div>"+
        "</div>"+
        "<div class=\"col-md-7 col-lg-12 col-xl-7 h-100 px-0\">"+
        "<div class=\"p-4\">"+
        "<div class=\"d-flex align-items-center justify-content-between mb-3\">"+
        "<small class=\"bg-primary text-white rounded py-1 px-3\">₹"+listData[i]["Sales Rate"]+"</small>"+
        "<div class=\"ps-2\">"+
        "<small class=\"fa fa-star text-primary\"></small>"+
        "<small class=\"fa fa-star text-primary\"></small>"+
        "<small class=\"fa fa-star text-primary\"></small>"+
        "<small class=\"fa fa-star text-primary\"></small>"+
        "<small class=\"fa fa-star text-primary\"></small>"+
        "</div>"+
        "</div>"+
        "<h5 class=\"mb-3\"><a href=\"../vendor/"+listData[i]["company_name"]+"?rf="+listData[i]["party_id"]+"\">"+listData[i]["company_name"]+"</a></h5>"+
        "<div class=\"d-flex mb-3\">"+
        "<small class=\"border-end me-3 pe11-3\"><i class=\"fa fa-bed text-primary me-2\"></i>"+listData[i]["name"]+"</small>"+
        //"<small class=\"border-end me-3 pe-3\"><i class=\"fa fa-bath text-primary me-2\"></i>2 Bath</small>"+
        //"<small><i class=\"fa fa-wifi text-primary me-2\"></i>Wifi</small>"+
        "</div>"+
        //"<p class=\"text-body mb-0\">Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet.</p>"+
        "</div>"+
        "<div class=\"d-flex justify-content-between border-top mt-auto p-4\">"+
        "<a class=\"btn btn-sm btn-primary rounded py-2 px-2\" href=\"../vendor/"+listData[i]["company_name"]+"?rf="+listData[i]["party_id"]+"\">View Detail</a>"+
        "<a class=\"btn btn-sm btn-dark _addToCart rounded py-2 px-2\">Book Now</a>"+
        "</div>"+
        "</div>"+
        "</div>"+
        "</form>"+
        "</div>"; 
        */


     }
    return strHeader+strResp+strFooter;
}
export function Vendor_Listing_Horizontal_NN(lsData){
    var appUrtPath=appGetUrlPath();
    var listData=JSON.parse(lsData);
    var strHeader="<div class=\"shop-category-area pb-10px pt-70px\">"+
    "<div class=\"container\">"+
        "<div class=\"row\">"+
            "<div class=\"col-lg-12 order-lg-last col-md-12 order-md-first\">"+
            "<div class=\"shop-bottom-area\">"+
            "<div class=\"row\">";
     var strFooter="</div></div></div></div></div></div>";
     var strResp="";
     for(var i=0;i<listData.length;i++){
        var imgPath="default-product.png"; 
        var urlTitle= listData[i]["icon_img"];
        
        urlTitle=urlTitle.replace("?","_");
        urlTitle=urlTitle.replace(" ","-");
        /*
        if(listData[i]["icon_img"]!=""){
         imgPath="app_user_img/"+listData[i]["icon_img"];
          }
          */
         imgPath=getRandomImagesPath();
         

        strResp+="<div class=\"col-md-6 pb-10\">"+
        "<form>"+
        "<input type=\"hidden\" name=\"item_name\" value=\""+listData[i]["name"]+"\" />"+
        "<input type=\"hidden\" name=\"item_code\" value=\""+listData[i]["Item Code"]+"\"/>"+
        "<input type=\"hidden\" name=\"item_rate\" value=\""+listData[i]["Sales Rate"]+"\" />"+
        "<input type=\"hidden\" name=\"comp_name\" value=\""+listData[i]["company_name"]+"\" />"+
        "<input type=\"hidden\" name=\"imgs\" value=\""+imgPath[0]+"\" />"+
        "<input type=\"hidden\" name=\"item_rf_id\" value=\""+listData[i]["id"]+"\" />"+
        "<input type=\"hidden\" name=\"parent_id\" value=\""+listData[i]["party_id"]+"\" />"+
        "<div class=\"card p-1 shadow\">"+
            "<div class=\"row\">"+
                "<div class=\"col-sm-6\">"+
                    "<a href=\"../vendor/"+listData[i]["company_name"]+"?rf="+listData[i]["party_id"]+"\"><img class=\"d-block w-100\" src=\"../"+imgPath+"\" alt=\"\"/></a>"+
                "</div>"+
                "<div class=\"col-sm-6\">"+
                    "<div class=\"card-block\">"+
                        "<div class=\"p-2 mb-1 alert-warning mybg text-white\">"+
                            "<h5 class=\"mb-0\"><a href=\"../vendor/"+listData[i]["company_name"]+"?rf="+listData[i]["party_id"]+"\">"+listData[i]["company_name"]+"</a></h5>"+
                            "<i class=\"fa fa-star text-warning\"></i>"+
                            "<i class=\"fa fa-star text-warning\"></i>"+
                            "<i class=\"fa fa-star text-warning\"></i>"+
                            "<i class=\"fa fa-star text-dark\"></i>"+
                            "<i class=\"fa fa-star text-dark\"></i>"+
                        "</div>"+
                        "<p class=\"mb-0\">Test : <b>"+listData[i]["name"]+"</b></p>"+
                        "<p class=\"mb-0\">Rate : <b>₹"+listData[i]["Sales Rate"]+"</b></p>"+
                        "<p class=\"text-danger mb-0\">Other Services : </p>"+
                        // "<p class=\"mb-2\">Updated At : 16/02/2022</p>"+
                        "<a href=\"javascript:void(0)\" class=\"button-37 _addToCart btn-primary float-right\">Add to Cart</a>"+
                    "</div>"+
                "</div>"+
            "</div>"+
        "</div>"+
    "</form></div>"; 
     }
    return strHeader+strResp+strFooter;
}
export function Vendor_Listing(lsData){
    var appUrtPath=appGetUrlPath();
    var listData=JSON.parse(lsData);
        var strHeader="<div class=\"shop-category-area pb-10px pt-70px\">"+
        "<div class=\"container\">"+
            "<div class=\"row\">"+
                "<div class=\"col-lg-12 order-lg-last col-md-12 order-md-first\">"+
                "<div class=\"shop-bottom-area\">"+
                "<div class=\"row\">";
         var strFooter="</div></div></div></div></div></div>";
    
        var strResp="";
      for(var i=0;i<listData.length;i++){
        var imgPath="default-product.png"; 
        var urlTitle= listData[i]["icon_img"];
        
        urlTitle=urlTitle.replace("?","_");
        urlTitle=urlTitle.replace(" ","-");
        if(listData[i]["icon_img"]!=""){
         imgPath="app_user_img/"+listData[i]["icon_img"];
          }
          strResp+="<div class=\"vendor_thumb col-lg-3 col-6 col-md-6 col-sm-6\">"+
          "<div class=\"border-box-2 product mb-5\">"+
          "<form>"+
                "<div class=\"thumb\">"+
                  "<a href=\"blog\\"+urlTitle+"?rf="+lsData[i]["e_id"]+"\" class=\"image\">"+
                      "<img src=\""+appUrtPath+imgPath+"\" alt=\"Product\" />"+
                    //   "<img class=\"hover-image\" src=\""+appUrtPath+imgPath+"\" alt=\"Product\" />"+
                  "</a>"+
              "</div>"+
              "<div class=\"content\">"+
                  "<h5 class=\"title\"><a href=\"blog\\"+urlTitle+"?rf="+lsData[i]["e_id"]+"\">"+listData[i]["company_name"]+"</a></h5>"+
                //   "<span class=\"price\">"+
                //       "<span class=\"new\">"+listData[i]["col2"]+"</span>"+
                //   "</span>"+
              "</div>"+
          "</form>"+"</div>"+
      "</div>";
      }
      return strHeader+strResp+strFooter;
  }

export function Product_ListingLsVen(lsData,filters,isSearchBar=false,isFilterOutput=false){
     // alert("tesin"+lsData);
     var appUrtPath=appGetUrlPath();
     var mainUrl=getMainUrl();
 var listData=JSON.parse(lsData);


 let colsSet="col-lg-12 col-md-12";
 let strSearchBar="";
if(isSearchBar===true && isFilterOutput===false){
colsSet="col-lg-9 col-md-9";
//   searchbar logic
//strSearchBar="<div class=\"col-lg-3 col-md-3\">";
strSearchBar+=ReactDOMServer.renderToString(<Search_Filters filters={filters} />);
strSearchBar+="<div class=\"mobile-filter-button\">"+
 "<a href=\"#0\" class=\"btn btn-warning open_filters filter-button\">"+
     "<i class=\"ion-ios-color-filter-outline12 ti-filter12 fa fa-sliders white my-float\"></i>"+
 "</a>"+
"</div>";
//strSearchBar+="</div>";
}
let strSorting=ReactDOMServer.renderToString(<Comp_Sorting_Bar count={listData.length}/>);


var strHeader="<div class=\"shop-category-area pb-10px pt-10px\">"+
"<div class=\"container\">"+
    "<div class=\"row ser_comp_f\">"+
    strSearchBar+
        "<div class=\""+colsSet+" order-lg-last order-md-first\">"+
        "<div class=\"shop-bottom-area\">"+
        strSorting+
        "<div class=\"row\" id=\"pro_list_parent\">";

    //  var strHeader="<div class=\"shop-category-area pb-100px pt-70px\">"+
    //  "<div class=\"container\">"+
    //      "<div class=\"row\">"+
    //          "<div class=\"col-lg-12 order-lg-last col-md-12 order-md-first\">"+
    //          "<div class=\"shop-bottom-area\">"+
    //          "<div class=\"row\">";
      var strFooter="</div></div></div></div></div></div>";
 
      
 if(isFilterOutput){
    strHeader="";
     var strFooter="";
}

     var strResp="";
   for(var i=0;i<listData.length;i++){
 
     var imgPath=["default_product.png"];  
     //let imgRandImg=getRandomImagesPath();
     //let imgPath=[imgRandImg];
     
     if(listData[i]["main_img"]!==""){
      imgPath=listData[i]["main_img"].split(',');
       }else{
         imgPath="../"+getRandomImagesPath();
       }
       strResp+="<div class=\"col-lg-3 col-6\">"+
       "<div class=\"product mb-5\">"+
       "<form>"+
     "<input type=\"hidden\" name=\"item_name\" value=\""+listData[i]["name"]+"\" />"+
     "<input type=\"hidden\" name=\"item_code\" value=\""+listData[i]["Item Code"]+"\"/>"+
     "<input type=\"hidden\" name=\"item_rate\" value=\""+listData[i]["Sales Rate"]+"\" />"+
     "<input type=\"hidden\" name=\"comp_name\" value=\""+listData[i]["company_name"]+"\" />"+
     "<input type=\"hidden\" name=\"imgs\" value=\""+imgPath+"\" />"+
     "<input type=\"hidden\" name=\"item_rf_id\" value=\""+listData[i]["id"]+"\" />"+
           "<div class=\"thumb\">"+
               "<a href=\"../vendors/"+listData[i]["name"].replace(/\s/g, '_')+"?rf="+listData[i]["Item Code"]+"\" class=\"image\">"+
                   "<img src=\""+imgPath+"\" alt=\"Product\" />"+
                   "<img class=\"hover-image\" src=\""+imgPath+"\" alt=\"Product\" />"+
               "</a>"+
               "<span class=\"badges\">"+
                   "<span class=\"new\">New</span>"+
               "</span>"+
               "<div class=\"actions\">"+
                 //   "<a href=\"wishlist.html\" class=\"action wishlist\" title=\"Wishlist\"><i class=\"icon-heart\"></i></a>"+
                 //   "<a href=\"#\" class=\"action quickview\" data-link-action=\"quickview\" title=\"Quick view\" data-bs-toggle=\"modal\" data-bs-target=\"#exampleModal\"><i class=\"icon-size-fullscreen\"></i></a>"+
                 //   "<a href=\"compare.html\" class=\"action compare\" title=\"Compare\"><i class=\"icon-refresh\"></i></a>"+
               "</div>"+
            //    "<button type=\"button\" title=\"Add To Cart\" class=\"_addToCart add-to-cart\">Add To Cart</button>"+
           "</div>"+
           "<div class=\"content\">"+
               "<h5 class=\"title\"><a href=\"../vendors/"+listData[i]["name"].replace(/\s/g, '_')+"?rf="+listData[i]["Item Code"]+"\">"+listData[i]["name"]+"</a></h5>"+
            //    "<span class=\"price\">"+
            //        "<span class=\"new\">₹"+listData[i]["Sales Rate"]+"</span>"+
            //    "</span>"+
           "</div>"+
       "</form>"+"</div>"+
   "</div>";
   }
   return strHeader+strResp+strFooter;
}  
export function Product_Listing(lsData){
    // alert("tesin"+lsData);
    var appUrtPath=appGetUrlPath();
    var mainUrl=getMainUrl();
var listData=JSON.parse(lsData);
    var strHeader="<div class=\"shop-category-area pb-100px pt-70px\">"+
    "<div class=\"container\">"+
        "<div class=\"row\">"+
            "<div class=\"col-lg-12 order-lg-last col-md-12 order-md-first\">"+
            "<div class=\"shop-bottom-area\">"+
            "<div class=\"row\">";
     var strFooter="</div></div></div></div></div></div>";

    var strResp="";
  for(var i=0;i<listData.length;i++){

    var imgPath=["default_product.png"];  
    //let imgRandImg=getRandomImagesPath();
    //let imgPath=[imgRandImg];
    
    if(listData[i]["main_img"]!==""){
     imgPath=listData[i]["main_img"].split(',');
      }else{
        imgPath="../"+getRandomImagesPath();
      }
      strResp+="<div class=\"col-lg-3 col-6\">"+
      "<div class=\"product mb-5\">"+
      "<form>"+
    "<input type=\"hidden\" name=\"item_name\" value=\""+listData[i]["name"]+"\" />"+
    "<input type=\"hidden\" name=\"item_code\" value=\""+listData[i]["Item Code"]+"\"/>"+
    "<input type=\"hidden\" name=\"item_rate\" value=\""+listData[i]["Sales Rate"]+"\" />"+
    "<input type=\"hidden\" name=\"comp_name\" value=\""+listData[i]["company_name"]+"\" />"+
    "<input type=\"hidden\" name=\"imgs\" value=\""+imgPath+"\" />"+
    "<input type=\"hidden\" name=\"item_rf_id\" value=\""+listData[i]["id"]+"\" />"+
          "<div class=\"thumb\">"+
              "<a href=\""+mainUrl+"product\\"+listData[i]["name"].replace(/\s/g, '_')+"?rf="+listData[i]["id"]+"\" class=\"image\">"+
                  "<img src=\""+imgPath+"\" alt=\"Product\" />"+
                  "<img class=\"hover-image\" src=\""+imgPath+"\" alt=\"Product\" />"+
              "</a>"+
              "<span class=\"badges\">"+
                  "<span class=\"new\">New</span>"+
              "</span>"+
              "<div class=\"actions\">"+
                //   "<a href=\"wishlist.html\" class=\"action wishlist\" title=\"Wishlist\"><i class=\"icon-heart\"></i></a>"+
                //   "<a href=\"#\" class=\"action quickview\" data-link-action=\"quickview\" title=\"Quick view\" data-bs-toggle=\"modal\" data-bs-target=\"#exampleModal\"><i class=\"icon-size-fullscreen\"></i></a>"+
                //   "<a href=\"compare.html\" class=\"action compare\" title=\"Compare\"><i class=\"icon-refresh\"></i></a>"+
              "</div>"+
              "<button type=\"button\" title=\"Add To Cart\" class=\"_addToCart add-to-cart\">Add To Cart</button>"+
          "</div>"+
          "<div class=\"content\">"+
              "<h5 class=\"title\"><a href=\""+mainUrl+"Medicine_Page\\"+listData[i]["name"].replace(/\s/g, '_')+"?rf="+listData[i]["id"]+"\">"+listData[i]["name"]+"</a></h5>"+
              "<span class=\"price\">"+
                  "<span class=\"new\">₹"+listData[i]["Sales Rate"]+"</span>"+
              "</span>"+
          "</div>"+
      "</form>"+"</div>"+
  "</div>";
  }
  return strHeader+strResp+strFooter;

}
export default Product_Listing;