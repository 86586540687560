import {getRandomImagesPath} from '../function_lib/random_images';
import './comp_vendor_listing.css'

export const Comp_Vendor_Listing=(props)=>{
    let imgPath="default-product.png"; 
    let urlTitle="";
    let vendUrlHref="";
    if(typeof props.vendData!=="undefined"){
       // alert(JSON.stringify(props.vendData));
        
         urlTitle= props.vendData["icon_img"];
     
     urlTitle=urlTitle.replace("?","_");
     urlTitle=urlTitle.replace(" ","-");
     imgPath=getRandomImagesPath();
     vendUrlHref="../vendor/"+props.vendData["company_name"]+"?rf="+props.vendData["party_id"];
     if(typeof props.vendData["app_url"]!=="undefined"){
        if(props.vendData["app_url"]!=="null"){
            vendUrlHref="../vendor/"+props.vendData["app_url"];
        }
     }
     //alert(imgPath);
    }
    return(<>
    {typeof props.vendData!=="undefined"?
     

<div class="col-md-12 mx-auto mb-4">
            <div class="card new-card-preview01">
              
                <div class="ribbon">
                    <span>Verified</span>
                </div>
                <form>
        <input type="hidden" name="item_name" value={props.vendData["name"]} />
        <input type="hidden" name="item_code" value={props.vendData["Item Code"]}/>
        <input type="hidden" name="item_rate" value={props.vendData["Sales Rate"]}/>
        <input type="hidden" name="comp_name" value={props.vendData["company_name"]} />
        <input type="hidden" name="imgs" value={imgPath} />
        <input type="hidden" name="item_rf_id" value={props.vendData["id"]} />
        <input type="hidden" name="parent_id" value={props.vendData["party_id"]} />
                <div class="row g-0">
                  
                    <div class="col-md-3 thumbnail-container">
                        <a href={vendUrlHref}>
                        <img src={"../"+imgPath} class="circular-thumbnail" alt="Hospital Image"/>
                        </a>
                    </div>
                    
                  
                    <div class="col-md-9 content-container">
                        <div class="text-end p-2">
                        <span class="badge badge-premium">Popular</span><span class="rating">&#9733;&#9733;&#9733;&#9733;&#9734;</span> (4.0)
                        </div>
                        <div class="d-flex justify-content-between align-items-center p-2">
                            <h5 class="card-title">
                                <a href={vendUrlHref}>
                                {props.vendData["company_name"]}
                                </a>
                               
                            </h5>
                            <span class="price">₹ {props.vendData["Sales Rate"]}</span>
                        </div>
                        <p class="card-text p-2 m-0 text-start">Looking for: <b>{props.vendData["name"]}</b></p>
                        <div class="d-flex mt-1">

                            <span class="badge bg-info mr-2">Laboratory</span>
                            <span class="badge bg-secondary mr-2">Pathology</span>
                            <span class="badge bg-success">Diagnostic</span>
                        </div>
                        <div class="d-flex justify-content-md-end py-1">
                            <a href={vendUrlHref} class="btn m-2 btn-outline-primary">View Details</a>
                            <a href="javascript:void(0)" class="btn _addToCart m-2 btn-success">Book Now</a>
                        </div>
                    </div>
                </div>
                </form>
            </div>
        </div>:''}
        </>

    );
}


export default Comp_Vendor_Listing