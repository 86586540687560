import './comp_advaiet_card_with_circular_icon_center.css'

export const Comp_Advaiet_Card_With_Circular_Icon_Center=(props)=>{
    return(

        <div class="container mt-5">
    <div class="custom-box-circular-icon">
        <div class="icon-wrapper">
            <img src="https://img.freepik.com/free-vector/person-working-science-lab_23-2148489265.jpg?t=st=1741078078~exp=1741081678~hmac=aca77863ba6165255ee9214b471d7269c80edb9932b60880551291ea407d2fc4&w=740" />
        </div>
        <h6>At Advaiet Health Services</h6>
        <p class="text-secondary">we are committed to delivering accurate, reliable, and timely diagnostic solutions to empower better health decisions. Our state-of-the-art pathology lab connects customers with advanced testing services, ensuring precise results with the highest standards of quality and care.

            With a seamless online platform, we make health checkups convenient—allowing you to book tests, receive reports digitally, and access expert consultation, all from the comfort of your home. Whether routine screenings or specialized diagnostics, we prioritize efficiency, affordability, and patient well-being.
            
            Your health is our priority. Experience excellence in diagnostics with Advaiet Health Services—where precision meets care!</p>
    </div>
</div>
    );
}

export default Comp_Advaiet_Card_With_Circular_Icon_Center;