import './comp_advaiet_how_it_works.css'


export const Comp_Advaiet_How_It_Works=(props)=>{
    return(

    <div class="container-fluid mt-5">
        <div class="how-it-works text-center">
        <h2>How it works</h2>
        
        <div class="row position-relative">
         
            <div class="col-9 mx-auto">
                <div class="process-line d-none d-md-block"></div>
            </div>
            
           
            <div class="col-md-4 mb-4 mb-md-0">
                <div class="process-circle">
                    <i class="step-icon">
                    <svg width="64px" height="64px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M5.5 8.80835C5.5 8.80835 5 10.6111 5 13.5C5 16.3889 5.38889 18.9167 5.77778 19.2778C6.16667 19.6389 8.88889 20 12 20C15.1111 20 17.8333 19.6389 18.2222 19.2778C18.6111 18.9167 19 16.3889 19 13.5C19 10.6111 18.5 8.80835 18.5 8.80835M5.5 8.80835C6.65798 8.91328 9.19021 9 12 9C14.8098 9 17.342 8.91328 18.5 8.80835M5.5 8.80835C5.19265 8.7805 4.98211 8.75135 4.88889 8.72222C4.44444 8.58333 4 7.61111 4 6.5C4 5.38889 4.44444 4.41667 4.88889 4.27778C5.33333 4.13889 8.44444 4 12 4C15.5556 4 18.6667 4.13889 19.1111 4.27778C19.5556 4.41667 20 5.38889 20 6.5C20 7.61111 19.5556 8.58333 19.1111 8.72222C19.0179 8.75135 18.8074 8.7805 18.5 8.80835M10 13C10 13 10.5 12.5 12 12.5C13.5 12.5 14 13 14 13" stroke="#F28C28" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                    </i>
                </div>
                <p class="step-text">Select a Test or Health Package</p>
            </div>
            
           
            <div class="col-md-4 mb-4 mb-md-0">
                <div class="process-circle">
                    <i class="step-icon">
                    <svg 
                        fill="#F28C28" 
                        width="64px" 
                        height="64px" 
                        viewBox="0 0 32 32" 
                        style={{ fillRule: "evenodd", clipRule: "evenodd", strokeLinejoin: "round", strokeMiterlimit: 2 }} 
                        version="1.1" 
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g id="SVGRepo_iconCarrier">
                            <path d="M10.998,4l0,21.079c0,2.76 2.241,5 5,5c2.76,0 5,-2.24 5,-5l0,-21.079l2.002,0c0.552,-0 1,-0.448 1,-1c-0,-0.552 -0.448,-1 -1,-1l-14,0c-0.552,-0 -1,0.448 -1,1c-0,0.552 0.448,1 1,1l1.998,0Zm8,5.758c-0.363,0.126 -0.773,0.196 -1.22,0.161c-0.725,-0.058 -1.568,-0.361 -2.458,-1.186c-0.93,-0.864 -1.854,-0.517 -2.322,-0.234l0,2.483l2.011,0.018c0.552,0.005 0.996,0.457 0.991,1.009c-0.005,0.552 -0.457,0.996 -1.009,0.991l-1.993,-0.018l0,1.997l2.011,0.018c0.552,0.005 0.996,0.457 0.991,1.009c-0.005,0.552 -0.457,0.996 -1.009,0.991l-1.993,-0.018l0,1.997l2.011,0.018c0.552,0.005 0.996,0.457 0.991,1.009c-0.005,0.552 -0.457,0.996 -1.009,0.991l-1.993,-0.018l0,1.997l2.011,0.018c0.552,0.005 0.996,0.457 0.991,1.009c-0.005,0.552 -0.457,0.996 -1.009,0.991l-1.993,-0.018l0,0.106c0,1.656 1.345,3 3,3c1.656,0 3,-1.344 3,-3l0,-15.321Zm0,-2.18l0,-3.578l-6,-0l0,2.326c1.003,-0.31 2.339,-0.306 3.683,0.941c0.462,0.429 0.879,0.628 1.256,0.658c0.466,0.037 0.838,-0.172 1.061,-0.347Z"></path>
                        </g>
                    </svg>


                    </i>
                </div>
                <p class="step-text">Choose a Lab or Service Location </p>
            </div>
            
           
            <div class="col-md-4">
                <div class="process-circle">
                    <i class="step-icon">
                        <svg width="45px" height="45px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M10 15H14M12 13V17M13 3H8.2C7.0799 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.0799 5 6.2V17.8C5 18.9201 5 19.4802 5.21799 19.908C5.40973 20.2843 5.71569 20.5903 6.09202 20.782C6.51984 21 7.0799 21 8.2 21H15.8C16.9201 21 17.4802 21 17.908 20.782C18.2843 20.5903 18.5903 20.2843 18.782 19.908C19 19.4802 19 18.9201 19 17.8V9M13 3L19 9M13 3V7.4C13 7.96005 13 8.24008 13.109 8.45399C13.2049 8.64215 13.3578 8.79513 13.546 8.89101C13.7599 9 14.0399 9 14.6 9H19" stroke="#F28C28" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>

                    </i>
                </div>
                <p class="step-text">Get Tested & Receive Reports Online</p>
            </div>
        </div>
    </div>
    </div>
    );
}


export default Comp_Advaiet_How_It_Works;